<style scoped>
/* .field-error {
  color: red;
} */

.invalid input {
  border: 1px #EB0600 solid
}

.invalid {
  color: #EB0600
}
</style>
<script>
import 'vue-select/dist/vue-select.css';
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from 'axios';
import Swal from "sweetalert2";
import Vue from 'vue';
import moment from 'moment';

import VueLoading from 'vuejs-loading-plugin'

import vSelect from 'vue-select'

Vue.component('v-select', vSelect)

// using default options
Vue.use(VueLoading)

// overwrite defaults
Vue.use(VueLoading, {
  dark: true, // default false
  text: 'Loading', // default 'Loading'
  loading: true, // default false
  //customLoader: myVueComponent, // replaces the spinner and text with your own
  background: 'rgb(255,255,255)', // set custom background
  classes: ['myclass'] // array, object or string
})



/**
 * Advanced table component
 */
export default {
  page: {
    title: "Data Pemeliharaan",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      // tableData: tableData,
      title: "Data Pemeliharaan",
      items: [
        {
          text: "Pantau Kebun",
          href: "/"
        },
        {
          text: "Data Pemeliharaan",
          active: true
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "nama",
      sortDesc: false,
      fields: [
        { key: "kode_lokasi", label: 'Kode Lokasi', sortable: true },
        { key: "waktu_tanam", label: 'Tanggal Tanam', sortable: true },
        { key: "ch_tanggal", label: 'Tanggal Chopping', sortable: true },
        { key: "pp_tanggal", label: 'Tanggal Panen Pipil', sortable: true },
        { key: "warning", label: 'Warning', sortable: true },
        { key: "Aksi", label: 'Aksi',thStyle: { width: "10%" },},
      ],
      baseapi: localStorage.getItem("baseapi"),
      user: JSON.parse(localStorage.getItem('user')),
      pemeliharaan: [],
      datapemeliharaan: [],
      datalokasi: [],
      datapenanaman: [],
      formTambah:{
        id_lokasi:null,
        id_penanaman:null
      },
      form: {
        id: null,
        id_lokasi: null,
        kode_lokasi: null,
        id_penanaman: null,
        waktu_tanam: null,
        tgl_penyiangan: null,
        tgl_pembubunan: null,
        penemuan_hama: null,
        penemuan_penyakit: null,
        p1_tanggal_pemupukan: null,
        p1_nama_pupuk_1: null,
        p1_dosis_pupuk_1: null,
        p1_nama_pupuk_2: null,
        p1_dosis_pupuk_2: null,
        p1_nama_pupuk_3: null,
        p1_dosis_pupuk_3: null,
        penyulaman_tanggal: null,
        penyulaman_jumlah: 0,
        pg_tanggal_herbisida: null,
        pg_nama_herbisida_1: null,
        pg_dosis_herbisida_1: null,
        pg_nama_herbisida_2: null,
        pg_dosis_herbisida_2: null,
        opt_tanggal_fungisida: null,
        opt_nama_fungisida_1: null,
        opt_dosis_fungisida_1: null,
        opt_nama_fungisida_2: null,
        opt_dosis_fungisida_2: null,
        p2_tanggal_pemupukan: null,
        p2_nama_pupuk_1: null,
        p2_dosis_pupuk_1: null,
        p2_nama_pupuk_2: null,
        p2_dosis_pupuk_2: null,
        p2_nama_pupuk_3: null,
        p2_dosis_pupuk_3: null,
        pa_tanggal: null,
        mkp1_tanggal_pemupukan: null,
        mkp1_dosis_pupuk: null,
        pch35_tanggal: null,
        pch35_nama_fungi_insektisida_1: null,
        pch35_dosis_fungi_insektisida_1: null,
        pch35_nama_fungi_insektisida_2: null,
        pch35_dosis_fungi_insektisida_2: null,
        mkp2_tanggal_pemupukan: null,
        mkp2_dosis_pupuk: null,
        pch50_tanggal: null,
        pch50_nama_fungi_insektisida_1: null,
        pch50_dosis_fungi_insektisida_1: null,
        pch50_nama_fungi_insektisida_2: null,
        pch50_dosis_fungi_insektisida_2: null,
        pr_tanggal: null,
        ch_tanggal: null,
        pp_tanggal: null,
      }
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.pemeliharaan.length;
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.getlokasi()
    this.loaddata()
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    // ubah format tanggal
    format_date(value) {
      if (value) {
        return moment(String(value)).format('DD-MM-YYYY')
      }
    },

    // loadData Kelompok Tani
    loaddata() {
      this.$loading(true);
      axios.get(this.baseapi + "api/pemeliharaan", {
        headers: {
          Authorization: `Bearer ` + this.user.accessToken
        }
      }).then(response => {
        console.log(response.data)
        this.$loading(false);
        this.pemeliharaan = response.data;
      }).catch(error => {
        this.$loading(false);
        if (error.response.data.message === 'Unauthorized!') {
          Swal.fire({
            title: "Akses Anda Terputus",
            text: "Silahkan Login Kembali",
            type: "error",
          }).then((r) => {
            console.log(r)
            localStorage.removeItem('user');
            this.$router.push({ path: '/login' })
          });
        }
        console.log(error)
        return error
      });
    },

    // data hak akses
    getlokasi() {
      axios.get(this.baseapi + 'api/lokasi', {
        headers: {
          Authorization: `Bearer ` + this.user.accessToken
        }
      })
        .then(response => {
          this.datalokasi = response.data
        })
        .catch((error) => {
          console.log(error)
          return error
        })
    },

    // data hak akses
    getpenanaman(id) {
      axios.get(this.baseapi + 'api/penanaman?filter=id_lokasi,=,' + id, {
        headers: {
          Authorization: `Bearer ` + this.user.accessToken
        }
      })
        .then(response => {
          this.datapenanaman = response.data
        })
        .catch((error) => {
          console.log(error)
          return error
        })
    },

    tambahdata() {
      axios
          .post(
            this.baseapi + "api/pemeliharaan",
            {
              id_lokasi: this.formTambah.id_lokasi,
              id_penanaman: this.formTambah.id_penanaman,
              
            },
            {
              headers: {
                Authorization: `Bearer ` + this.user.accessToken
              }
            }
          )
          .then((res) => {
            Swal.fire({
              icon: 'success',
              title: "Data Berhasil Ditambah",
              showConfirmButton: false,
              timer: 1500
            })
            this.formTambah = []
            this.loaddata()
            return res;
          })
          .catch((err) => {
            console.log(err)
            Swal.fire({
              icon: 'error',
              title: err.response.data.message,
              showConfirmButton: false,
              timer: 1500
            })
            return err;
          });
          this.$nextTick(() => {
            this.$bvModal.hide('modal-tambah')
          });
      
      // Wait until the models are updated in the UI

      
      
    },
    // end tambah data

    // edit data
    editdata(id) {
      axios
          .put(
            this.baseapi + "api/pemeliharaan/"+id,
            {
              id_lokasi: this.form.id_lokasi,
              id_penanaman: this.form.id_penanaman,
              waktu_tanam: this.form.waktu_tanam,
              tgl_penyiangan: this.form.tgl_penyiangan,
              tgl_pembubunan: this.form.tgl_pembubunan,
              penemuan_hama: this.form.penemuan_hama,
              penemuan_penyakit: this.form.penemuan_penyakit,
              p1_tanggal_pemupukan: this.form.p1_tanggal_pemupukan,
              p1_nama_pupuk_1: this.form.p1_nama_pupuk_1,
              p1_dosis_pupuk_1: this.form.p1_dosis_pupuk_1,
              p1_nama_pupuk_2: this.form.p1_nama_pupuk_2,
              p1_dosis_pupuk_2: this.form.p1_dosis_pupuk_2,
              p1_nama_pupuk_3: this.form.p1_nama_pupuk_3,
              p1_dosis_pupuk_3: this.form.p1_dosis_pupuk_3,
              penyulaman_tanggal: this.form.penyulaman_tanggal,
              penyulaman_jumlah: this.form.penyulaman_jumlah,
              pg_tanggal_herbisida: this.form.pg_tanggal_herbisida,
              pg_nama_herbisida_1: this.form.pg_nama_herbisida_1,
              pg_dosis_herbisida_1: this.form.pg_dosis_herbisida_1,
              pg_nama_herbisida_2: this.form.pg_nama_herbisida_2,
              pg_dosis_herbisida_2: this.form.pg_dosis_herbisida_2,
              opt_tanggal_fungisida: this.form.opt_tanggal_fungisida,
              opt_nama_fungisida_1: this.form.opt_nama_fungisida_1,
              opt_dosis_fungisida_1: this.form.opt_dosis_fungisida_1,
              opt_nama_fungisida_2: this.form.opt_nama_fungisida_2,
              opt_dosis_fungisida_2: this.form.opt_dosis_fungisida_2,
              p2_tanggal_pemupukan: this.form.p2_tanggal_pemupukan,
              p2_nama_pupuk_1: this.form.p2_nama_pupuk_1,
              p2_dosis_pupuk_1: this.form.p2_dosis_pupuk_1,
              p2_nama_pupuk_2: this.form.p2_nama_pupuk_2,
              p2_dosis_pupuk_2: this.form.p2_dosis_pupuk_2,
              p2_nama_pupuk_3: this.form.p2_nama_pupuk_3,
              p2_dosis_pupuk_3: this.form.p2_dosis_pupuk_3,
              pa_tanggal: this.form.pa_tanggal,
              mkp1_tanggal_pemupukan: this.form.mkp1_tanggal_pemupukan,
              mkp1_dosis_pupuk: this.form.mkp1_dosis_pupuk,
              pch35_tanggal: this.form.pch35_tanggal,
              pch35_nama_fungi_insektisida_1: this.form.pch35_nama_fungi_insektisida_1,
              pch35_dosis_fungi_insektisida_1: this.form.pch35_dosis_fungi_insektisida_1,
              pch35_nama_fungi_insektisida_2: this.form.pch35_nama_fungi_insektisida_2,
              pch35_dosis_fungi_insektisida_2: this.form.pch35_dosis_fungi_insektisida_2,
              mkp2_tanggal_pemupukan: this.form.mkp2_tanggal_pemupukan,
              mkp2_dosis_pupuk: this.form.mkp2_dosis_pupuk,
              pch50_tanggal: this.form.pch50_tanggal,
              pch50_nama_fungi_insektisida_1: this.form.pch50_nama_fungi_insektisida_1,
              pch50_dosis_fungi_insektisida_1: this.form.pch50_dosis_fungi_insektisida_1,
              pch50_nama_fungi_insektisida_2: this.form.pch50_nama_fungi_insektisida_2,
              pch50_dosis_fungi_insektisida_2: this.form.pch50_dosis_fungi_insektisida_2,
              pr_tanggal: this.form.pr_tanggal,
              ch_tanggal: this.form.ch_tanggal,
              pp_tanggal: this.form.pp_tanggal,
            },
            {
              headers: {
                Authorization: `Bearer ` + this.user.accessToken
              }
            }
          )
          .then((res) => {
            Swal.fire({
              icon: 'success',
              title: "Data Berhasil Diubah",
              showConfirmButton: false,
              timer: 1500
            })
            this.datapemeliharaan = []
            this.form = []
            this.loaddata()
            return res;
          })
          .catch((err) => {
            console.log(err)
            Swal.fire({
              icon: 'error',
              title: err.response.data.message,
              showConfirmButton: false,
              timer: 1500
            })
            return err;
          });

          this.$nextTick(() => {
            this.$bvModal.hide('modal-edit')
          });
    },

    // mendapatkan data pemeliharaan
    getperdata(data) {
      console.log(data)
      this.form = data
      this.getpenanaman(this.form.id_lokasi);
      this.$nextTick(() => {
          this.$bvModal.show('modal-edit')
        })
      // axios.get(this.baseapi + `api/pemeliharaan/${id}`, {
      //   headers: {
      //     Authorization: `Bearer ` + this.user.accessToken
      //   }
      // }).then(response => {
      //   this.datapemeliharaan = response.data;
      //   this.getpenanaman(this.datapemeliharaan.id_lokasi)
      // });
    },

    // hapusdata
    hapusdata(id) {
      Swal.fire({
        title: 'Hapus Data?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Batal',
        confirmButtonText: 'Ya'
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          axios
            .delete(
              this.baseapi + `api/pemeliharaan/` + id,
              {
                headers: {
                  Authorization: `Bearer ` + this.user.accessToken
                }
              }
            )
            .then((res) => {
              Swal.fire({
                icon: 'success',
                title: "Data Berhasil Dihapus",
                showConfirmButton: false,
                timer: 1500
              })
              this.loaddata()
              return res;
            })
            .catch((err) => {
              console.log(err)
              Swal.fire({
                icon: 'error',
                title: err.response.data.message,
                showConfirmButton: false,
                timer: 1500
              })
              return err;
            });
        }
      })
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="card-title">Data Table</h4> -->
            <div class="form-group row">
              <div class="col-sm-6">
                <b-button variant="success" v-b-modal.modal-tambah><i class="fas fa-plus"></i> Tambah</b-button>
              </div>
              <div class="col-sm-6 text-end">
              </div>
            </div>
            <p class="card-title-desc"></p>
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">

              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input v-model="filter" type="search" placeholder="Search..."
                      class="form-control form-control-sm ml-2"></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0 datatables">
              <b-table :items="pemeliharaan" :fields="fields" responsive="sm" :per-page="perPage"
                :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                :filter-included-fields="filterOn" @filtered="onFiltered">
                <template #cell(penjarangan_penyulaman)="data">
                  {{ format_date(data.item.penjarangan_penyulaman) }}
                </template>
                <template #cell(tgl_penyiangan)="data">
                  {{ format_date(data.item.tgl_penyiangan) }}
                </template>
                <template #cell(tgl_pembubunan)="data">
                  {{ format_date(data.item.tgl_pembubunan) }}
                </template>
                <template #cell(aksi)="data">
                  <b-button variant="info" style="margin-right:5px"
                    @click="getperdata(data.item)"><i class="fas fa-edit"></i></b-button>
                  <b-button variant="danger" @click="hapusdata(data.item.id)"><i class="far fa-trash-alt"></i></b-button>
                </template>
              </b-table>
            </div>
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show
                    <b-form-select class="form-control form-control-sm form-select form-select-sm" v-model="perPage"
                      size="sm" :options="pageOptions"></b-form-select>entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">

              </div>
              <!-- End search -->
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- modal edit -->
    <b-modal id="modal-edit" scrollable title="Edit Data" cancel-title="Close" ok-title="Accept"
      cancel-variant="outline-secondary" size="fullscreen">
      <ValidationObserver ref="form">
        <form ref="form" @submit.stop.prevent="tambahdata">
          <b-row>
            <b-col md="12">
              <b-row>
                <b-col md="3">
                  <b-form-group label="Lokasi" label-for="name" class="mb-3">
                      <v-select style="color:#2E3648;" :options="datalokasi" @input="getpenanaman(form.id_lokasi)"
                        v-model="form.id_lokasi" :reduce="(datalokasi) => datalokasi.id" label="kode_lokasi" />
                      
                  </b-form-group>
                </b-col>

                <b-col md="3">
                  <b-form-group label="Tanggal Penanaman" label-for="name" class="mb-3">
                      <v-select style="color:#2E3648;" :options="datapenanaman" v-model="form.id_penanaman"
                        :reduce="(datapenanaman) => datapenanaman.id" label="waktu_tanam" />
                      
                  </b-form-group>
                </b-col>
              </b-row>

            </b-col>

            <b-col md="6">
              <b-card>
                <h5 slot="header" class="mb-0">Pemupukan I (<span style="color:red">7HST</span>)</h5>
                <b-card-text>
                  <b-row>
                    <b-col md="4">
                      <div class="table-responsive mb-0">
                        <table class="table table-borderless mb-0">
                          <thead>
                            <tr>
                              <th>Tanggal Pemupukan</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">
                                <input type="date" class="form-control" v-model="form.p1_tanggal_pemupukan">
                              </th>
                            </tr>

                          </tbody>
                        </table>
                      </div>

                    </b-col>

                    <b-col md="8">
                      <div class="table-responsive mb-0">
                        <table class="table mb-0">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Nama Pupuk</th>
                              <th>Dosis</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">1</th>
                              <td>
                                <b-form-input id="name" v-model="form.p1_nama_pupuk_1" />
                              </td>
                              <td>
                                <b-form-input id="name" v-model="form.p1_dosis_pupuk_1" />
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">2</th>
                              <td>
                                <b-form-input id="name" v-model="form.p1_nama_pupuk_2" />
                              </td>
                              <td>
                                <b-form-input id="name" v-model="form.p1_dosis_pupuk_2" />
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">3</th>
                              <td>
                                <b-form-input id="name" v-model="form.p1_nama_pupuk_3" />
                              </td>
                              <td>
                                <b-form-input id="name" v-model="form.p1_dosis_pupuk_3" />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-card>
            </b-col>

            <b-col md="6">
              <b-card>
                <h5 slot="header" class="mb-0">Penyulaman (<span style="color:red">7HST</span>)</h5>
                <b-card-text>
                  <b-row>
                    <b-col md="6">
                      <b-form-group label="Tanggal Penyulaman" label-for="tanggal pesanan" class="mb-3">
                          <input type="date" class="form-control" v-model="form.penyulaman_tanggal">
                      </b-form-group>

                    </b-col>

                    <b-col md="6">
                      <b-form-group label="Jumlah Penyulaman" label-for="tanggal pesanan" class="mb-3">
                          <b-form-input id="name" v-model="form.penyulaman_jumlah" />
                      </b-form-group>

                    </b-col>

                  </b-row>
                </b-card-text>
              </b-card>
            </b-col>

            <b-col md="6">
              <b-card>
                <h5 slot="header" class="mb-0">Pengendalian Gulma (<span style="color:red">10HST</span>)</h5>
                <b-card-text>
                  <b-row>
                    <b-col md="4">
                      <div class="table-responsive mb-0">
                        <table class="table table-borderless mb-0">
                          <thead>
                            <tr>
                              <th>Tanggal Pemberian Herbisida</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">
                                <input type="date" class="form-control" v-model="form.pg_tanggal_herbisida">
                              </th>
                            </tr>

                          </tbody>
                        </table>
                      </div>

                    </b-col>

                    <b-col md="8">
                      <div class="table-responsive mb-0">
                        <table class="table mb-0">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Nama Herbisida</th>
                              <th>Dosis Herbisida</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">1</th>
                              <td>
                                <b-form-input id="name" v-model="form.pg_nama_herbisida_1" />
                              </td>
                              <td>
                                <b-form-input id="name" v-model="form.pg_dosis_herbisida_1" />
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">2</th>
                              <td>
                                <b-form-input id="name" v-model="form.pg_nama_herbisida_2" />
                              </td>
                              <td>
                                <b-form-input id="name" v-model="form.pg_dosis_herbisida_2" />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-card>
            </b-col>

            <b-col md="6">
              <b-card>
                <h5 slot="header" class="mb-0">Monitoring & Pengendalian OPT (<span style="color:red">15HST</span>)</h5>
                <b-card-text>
                  <b-row>
                    <b-col md="4">
                      <div class="table-responsive mb-0">
                        <table class="table table-borderless mb-0">
                          <thead>
                            <tr>
                              <th>Tanggal Pemberian Fungisida/Insektisida</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">
                                <input type="date" class="form-control" v-model="form.opt_tanggal_fungisida">
                              </th>
                            </tr>

                          </tbody>
                        </table>
                      </div>

                    </b-col>

                    <b-col md="8">
                      <div class="table-responsive mb-0">
                        <table class="table mb-0">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Nama Fungisida/Insektisida</th>
                              <th>Dosis Fungisida/Insektisida</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">1</th>
                              <td>
                                <b-form-input id="name" v-model="form.opt_nama_fungisida_1" />
                              </td>
                              <td>
                                <b-form-input id="name" v-model="form.opt_dosis_fungisida_1" />
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">2</th>
                              <td>
                                <b-form-input id="name" v-model="form.opt_nama_fungisida_2" />
                              </td>
                              <td>
                                <b-form-input id="name" v-model="form.opt_dosis_fungisida_2" />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-card>
            </b-col>


            <b-col md="6">
              <b-card>
                <h5 slot="header" class="mb-0">Pemupukan II (<span style="color:red">25HST</span>)</h5>
                <b-card-text>
                  <b-row>
                    <b-col md="4">
                      <div class="table-responsive mb-0">
                        <table class="table table-borderless mb-0">
                          <thead>
                            <tr>
                              <th>Tanggal Pemupukan</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">
                                <input type="date" class="form-control" v-model="form.p2_tanggal_pemupukan">
                              </th>
                            </tr>

                          </tbody>
                        </table>
                      </div>

                    </b-col>

                    <b-col md="8">
                      <div class="table-responsive mb-0">
                        <table class="table mb-0">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Nama Pupuk</th>
                              <th>Dosis Pupuk</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">1</th>
                              <td>
                                <b-form-input id="name" v-model="form.p2_nama_pupuk_1" />
                              </td>
                              <td>
                                <b-form-input id="name" v-model="form.p2_dosis_pupuk_1" />
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">2</th>
                              <td>
                                <b-form-input id="name" v-model="form.p2_nama_pupuk_2" />
                              </td>
                              <td>
                                <b-form-input id="name" v-model="form.p2_dosis_pupuk_2" />
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">3</th>
                              <td>
                                <b-form-input id="name" v-model="form.p2_nama_pupuk_3" />
                              </td>
                              <td>
                                <b-form-input id="name" v-model="form.p2_dosis_pupuk_3" />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-card>
            </b-col>


            <b-col md="2">
              <b-card>
                <h5 slot="header" class="mb-0">Pembumbunan (<span style="color:red">25HST</span>)</h5>
                <b-card-text>
                  <b-row>
                    <b-col md="12">
                      <b-form-group label="Tanggal Pembubunan" label-for="tanggal pesanan" class="mb-3">
                          <input type="date" class="form-control" v-model="form.pa_tanggal">
                          
                      </b-form-group>

                    </b-col>

                  </b-row>
                </b-card-text>
              </b-card>
            </b-col>

            <b-col md="4">
              <b-card>
                <h5 slot="header" class="mb-0">Pemupukan MKP I (<span style="color:red">30HST</span>)</h5>
                <b-card-text>
                  <b-row>
                    <b-col md="6">
                      <b-form-group label="Tanggal Pemupukan" label-for="tanggal pesanan" class="mb-3">
                        <input type="date" class="form-control" v-model="form.mkp1_tanggal_pemupukan">
                      </b-form-group>

                    </b-col>

                    <b-col md="6">
                      <b-form-group label="Dosis MKP" label-for="tanggal pesanan" class="mb-3">
                        <b-form-input id="name" v-model="form.mkp1_dosis_pupuk" />
                      </b-form-group>

                    </b-col>

                  </b-row>
                </b-card-text>
              </b-card>
            </b-col>


            <b-col md="6">
              <b-card>
                <h5 slot="header" class="mb-0">Pengendalian Cendawan dan Hama I(<span style="color:red">35HST</span>)</h5>
                <b-card-text>
                  <b-row>
                    <b-col md="4">
                      <div class="table-responsive mb-0">
                        <table class="table table-borderless mb-0">
                          <thead>
                            <tr>
                              <th>Tanggal Pemberian Fungi/Insektisida</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">
                                <input type="date" class="form-control" v-model="form.pch35_tanggal">
                              </th>
                            </tr>

                          </tbody>
                        </table>
                      </div>

                    </b-col>

                    <b-col md="8">
                      <div class="table-responsive mb-0">
                        <table class="table mb-0">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Nama Fungi/Insektisida</th>
                              <th>Dosis Fungi/Insektisida</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">1</th>
                              <td>
                                <b-form-input id="name" v-model="form.pch35_nama_fungi_insektisida_1" />
                              </td>
                              <td>
                                <b-form-input id="name" v-model="form.pch35_dosis_fungi_insektisida_1" />
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">2</th>
                              <td>
                                <b-form-input id="name" v-model="form.pch35_nama_fungi_insektisida_2" />
                              </td>
                              <td>
                                <b-form-input id="name" v-model="form.pch35_dosis_fungi_insektisida_2" />
                              </td>
                            </tr>

                          </tbody>
                        </table>
                      </div>
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-card>
            </b-col>

            <b-col md="6">
              <b-card>
                <h5 slot="header" class="mb-0">Pemupukan MKP II (<span style="color:red">45HST</span>)</h5>
                <b-card-text>
                  <b-row>
                    <b-col md="6">
                      <b-form-group label="Tanggal Pemupukan" label-for="tanggal pesanan" class="mb-3">
                        <input type="date" class="form-control" v-model="form.mkp2_tanggal_pemupukan">
                      </b-form-group>

                    </b-col>

                    <b-col md="6">
                      <b-form-group label="Dosis MKP" label-for="tanggal pesanan" class="mb-3">
                        <b-form-input id="name" v-model="form.mkp2_dosis_pupuk" />
                      </b-form-group>

                    </b-col>

                  </b-row>
                </b-card-text>
              </b-card>
            </b-col>

            <b-col md="6">
              <b-card>
                <h5 slot="header" class="mb-0">Pengendalian Cendawan dan Hama II(<span style="color:red">50HST</span>)</h5>
                <b-card-text>
                  <b-row>
                    <b-col md="4">
                      <div class="table-responsive mb-0">
                        <table class="table table-borderless mb-0">
                          <thead>
                            <tr>
                              <th>Tanggal Pemberian Fungi/Insektisida</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">
                                <input type="date" class="form-control" v-model="form.pch50_tanggal">
                              </th>
                            </tr>

                          </tbody>
                        </table>
                      </div>

                    </b-col>

                    <b-col md="8">
                      <div class="table-responsive mb-0">
                        <table class="table mb-0">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Nama Fungi/Insektisida</th>
                              <th>Dosis Fungi/Insektisida</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">1</th>
                              <td>
                                <b-form-input id="name" v-model="form.pch50_nama_fungi_insektisida_1" />
                              </td>
                              <td>
                                <b-form-input id="name" v-model="form.pch50_dosis_fungi_insektisida_1" />
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">2</th>
                              <td>
                                <b-form-input id="name" v-model="form.pch50_nama_fungi_insektisida_2" />
                              </td>
                              <td>
                                <b-form-input id="name" v-model="form.pch50_dosis_fungi_insektisida_2" />
                              </td>
                            </tr>

                          </tbody>
                        </table>
                      </div>
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-card>
            </b-col>

            <b-col md="2">
              <b-card>
                <h5 slot="header" class="mb-0">Pruning (<span style="color:red">80HST</span>)</h5>
                <b-card-text>
                  <b-row>
                    <b-col md="12">
                      <b-form-group label="Tgl Pelaksanaan" label-for="tanggal pesanan" class="mb-3">
                        <ValidationProvider name="Tanggal Pembubunan" rules="required" v-slot="{ classes, errors }">
                          <input type="date" class="form-control" v-model="form.pr_tanggal">
                          <span :class="classes">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-form-group>

                    </b-col>

                  </b-row>
                </b-card-text>
              </b-card>
            </b-col>

            <b-col md="2">
              <b-card>
                <h5 slot="header" class="mb-0">Chopping (<span style="color:red">90HST</span>)</h5>
                <b-card-text>
                  <b-row>
                    <b-col md="12">
                      <b-form-group label="Tgl Pelaksanaan" label-for="tanggal pesanan" class="mb-3">
                        <ValidationProvider name="Tanggal Pembubunan" rules="required" v-slot="{ classes, errors }">
                          <input type="date" class="form-control" v-model="form.ch_tanggal">
                          <span :class="classes">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-form-group>

                    </b-col>

                  </b-row>
                </b-card-text>
              </b-card>
            </b-col>

            <b-col md="2">
              <b-card>
                <h5 slot="header" class="mb-0">Panen Pipil (<span style="color:red;font-size: 12px;">100-120HST</span>)</h5>
                <b-card-text>
                  <b-row>
                    <b-col md="12">
                      <b-form-group label="Tgl Pelaksanaan" label-for="tanggal pesanan" class="mb-3">
                        <ValidationProvider name="Tanggal Pembubunan" rules="required" v-slot="{ classes, errors }">
                          <input type="date" class="form-control" v-model="form.pp_tanggal">
                          <span :class="classes">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-form-group>

                    </b-col>

                  </b-row>
                </b-card-text>
              </b-card>
            </b-col>

          </b-row>
        </form>
      </ValidationObserver>

      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" style="float:right" @click="editdata(form.id)">
            <i class="fas fa-save"></i>

            Simpan
          </b-button>
        </div>
      </template>
    </b-modal>

    <!-- modal tambah -->
    <b-modal id="modal-tambah" title="Tambah Data" cancel-title="Close" ok-title="Accept"
      cancel-variant="outline-secondary">

          <b-row>
                <b-col md="6">
                  <b-form-group label="Lokasi" label-for="name" class="mb-3">
                    <ValidationProvider name="Lokasi" rules="required" v-slot="{ classes, errors }">
                      <v-select style="color:#2E3648;" :options="datalokasi" @input="getpenanaman(formTambah.id_lokasi)"
                        v-model="formTambah.id_lokasi" :reduce="(datalokasi) => datalokasi.id" label="kode_lokasi" />
                      <span :class="classes">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group label="Tanggal Penanaman" label-for="name" class="mb-3">
                    <ValidationProvider name="Penanaman" rules="required" v-slot="{ classes, errors }">
                      <v-select style="color:#2E3648;" :options="datapenanaman" v-model="formTambah.id_penanaman"
                        :reduce="(datapenanaman) => datapenanaman.id" label="waktu_tanam" />
                      <span :class="classes">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
              </b-row>


      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" style="float:right" @click="tambahdata()">
            <i class="fas fa-save"></i>

            Simpan
          </b-button>
        </div>
      </template>
    </b-modal>
  </Layout>
</template>